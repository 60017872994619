import React from "react";
import { getUrlParams } from "./getUrlParams";
import Error404 from "../components/Error404";
import { clients } from "./clientData.js";

export const withParamsCheck = (WrappedComponent) => {
  return class extends React.Component {
    render() {
      const params = getUrlParams();
      const clientId = params["client_id"];

      const selectedClient = clients.find((client) => client.id === clientId);
      if (
        !selectedClient ||
        !params["response_type"] ||
        !params["redirect_uri"] ||
        !selectedClient.url.includes(params["redirect_uri"])
      ) {
        return <Error404 />;
      }
      return <WrappedComponent />;
    }
  };
};
