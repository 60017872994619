import React from "react";
import {
    Heading,
    View,
    Flex
} from "@aws-amplify/ui-react";
import Logo from "./Logo";
import {I18n} from "aws-amplify";
import { strings } from "../strings";
I18n.putVocabularies(strings);

const Error404 = ({ message }) => {
  return (
        <Flex
            paddingTop='150px'
            direction="column"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            wrap="nowrap"
            gap="1rem"
        >

            <View>
                <Logo />
            </View>
            <View>
                <Heading textAlign="center" level={1}>
                    {I18n.get("error_404")}
                </Heading>
            </View>
        </Flex>
  );
};

export default Error404;
