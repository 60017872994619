import { Auth } from "aws-amplify";

export async function checkSession() {
  try {
    const params = new URLSearchParams(window.location.search);
    const { redirect_uri, response_type, redirect_url, state, redirect, layout } =
      Object.fromEntries(params.entries());

    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();
    const expiresIn = session.getIdToken().payload["exp"];

    const redirectLoginUrlWithParams = `${redirect_uri}?id_token=${idToken}&expires_in=${expiresIn}&token_type=${response_type}${
      redirect_url ? `&redirect_url=${redirect_url}` : ""
    }${state ? `&state=${state}` : ""}${layout ? `&layout=${layout}` : ""}`;

    if (redirect === "true") {
      window.location.href = redirectLoginUrlWithParams;
    }

    const data = {
      redirectLoginUrlWithParams,
      session,
    };

    return { data };
  } catch (error) {
    return false;
  }
}
