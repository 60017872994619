import React from "react";
import { Flex, Image } from "@aws-amplify/ui-react";
import logo2 from "../logo-brand.png";

const Logo = () => {
  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      alignContent="flex-start"
      wrap="nowrap"
      gap="1rem"
    >
      <Image
        alt="Viseeon logo"
        src={logo2}
        objectFit="initial"
        objectPosition="50% 50%"
        backgroundColor="initial"
        opacity="100%"
      />
    </Flex>
  );
};
export default Logo;
