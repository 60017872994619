import { Loader } from "@aws-amplify/ui-react";
import React from "react";

export const LoaderColor = () => {
  return (
    <Loader
      size="small"
      variation="linear"
      emptyColor="white"
      filledColor="#ec6a2d"
    />
  );
};
