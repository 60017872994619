// App.jsx
import React, { useEffect, useState } from "react";
import { Flex, ThemeProvider, View } from "@aws-amplify/ui-react";
import { LoaderColor } from "./components/LoaderColor.jsx";
import LoginForm from "./components/LoginForm";
import ResetPassword from "./components/ResetPasswordForm.jsx";
import LoggedIn from "./components/LoggedIn";
import { checkSession } from "./utils/checkSession";
import { useRoutes } from "react-router-dom";
import "./App.css";
import { viseeonTheme } from "./theme";
import { getUrlParamsRequired } from "./utils/getUrlParams";

const App = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [redirectLoginUrlWithParams, setRedirectLoginUrlWithParams] =
    useState(null);

  useEffect(() => {
    handleIsLogged();
    if (
      getUrlParamsRequired.client_id === "6e45ui9ubcogik19htbr9ovipn" ||
      getUrlParamsRequired.client_id === "2d9hqrejlffigq6ng2uvvfgm50"
    ) {
      // Check if the page is opened in an iframe, and force it to open in the parent window (current tab)
      if (window.top !== window.self) {
        // Redirect the parent window to the current URL (detaching from the iframe)
        window.top.location.href = window.location.href;
      }
    }
  }, []);

  const handleIsLogged = async () => {
    setIsLoading(true);
    const result = await checkSession();
    setIsLoading(false);
    if (result) {
      setRedirectLoginUrlWithParams(result.data);
    }
  };

  let content = useRoutes([
    {
      path: "/login",
      element: redirectLoginUrlWithParams ? (
        <LoggedIn redirectLoginUrlWithParams={redirectLoginUrlWithParams} />
      ) : (
        <LoginForm isLoading={isLoading} />
      ),
    },
    {
      path: "/reset-password",
      element: <ResetPassword />,
    },
  ]);

  return (
    <ThemeProvider theme={viseeonTheme}>
      {isLoading && <LoaderColor />}
      <Flex
        paddingTop="150px"
        direction="column"
        justifyContent="space-around"
        alignItems="center"
        alignContent="flex-start"
        wrap="nowrap"
      >
        <View>{content}</View>
      </Flex>
    </ThemeProvider>
  );
};

export default App;
