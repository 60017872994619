export const viseeonTheme = {
    name: "viseeonTheme",
    tokens: {
        components: {
            card: {
                elevated: {
                    backgroundColor: { value: '{colors.background.primary}' },
                    boxShadow: { value: '{shadows.large}' },
                    borderRadius: { value: '8px' },
                },
            },
            button: {
                fontWeight: { value: '{fontWeights.extrabold}' },
                primary: {
                    backgroundColor: { value: '#ec6a2d' },
                    _hover: {
                        backgroundColor: { value: '#ec6a2d' },
                    },
                    _focus: {
                        backgroundColor: { value: '#ec6a2d' },
                    },
                    _active: {
                        backgroundColor: { value: '#ec6a2d' },
                    },
                },
                secondary: {
                    backgroundColor: { value: '#ec6a2d' },
                    _hover: {
                        backgroundColor: { value: '#ec6a2d' },
                    },
                    _focus: {
                        backgroundColor: { value: '#ec6a2d' },
                    },
                    _active: {
                        backgroundColor: { value: '#ec6a2d' },
                    },
                },
                loading: {
                    backgroundColor: { value: '#FFFF' },
                },
                disabled: {
                    backgroundColor: { value: '#FFFF' },
                }
            },
            textfield: {
                _focus: {
                    borderColor: { value: '#ec6a2d' },
                },
            },
            heading: {
                color: { value: '#ec6a2d' },
                1: {
                    fontSize: { value: '{fontSizes.xxl}' },
                    fontWeight: { value: '{fontWeights.bold}' },
                },
                4   : {
                    fontSize: { value: '{fontSizes.large}' },
                    fontWeight: { value: '{fontWeights.normal}' },
                },
            },
        },
        colors: {
            font: {
                // ...
            },
        },
    },
};