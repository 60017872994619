export function getUrlParams() {
  const params = new URLSearchParams(window.location.search);
  const result = {};

  params.forEach((value, key) => {
    result[key] = value;
  });

  return result;
}

export const getUrlParamsRequired = getUrlParams();