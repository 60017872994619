export const strings = {
  fr: {
    form_title: "Connexion sur",
    form_password_challenge_title: "Définir un nouveau mot de passe",
    form_login_alternative: "Ou par e-mail",
    form_field_email: "E-mail",
    form_field_code: "Code de Confirmation",
    form_field_email_placeholder: "Entrer votre e-mail",
    form_field_code_placeholder: "Saisissez le code de confirmation",
    form_field_reset_password_placeholder: "Réinitialisez votre mot de passe",
    form_field_password: "Mot de passe",
    form_field_password_challenge: "Nouveau mot de passe",
    form_field_password_placeholder: "Entrer votre mot de passe",
    form_field_password_confirm_challenge: "Confirmer le nouveau mot de passe",
    form_field_password_confirm_placeholder:
      "Confirmez votre nouveau mot de passe",
    form_btn_submit: "Connexion",
    form_btn_reset: "Réinitialiser le mot de passe",
    form_btn_code: "Envoyer l'email",
    form_advice:
      "Vous recevrez vos identifiants par e-mail, redirecton vers la page de connexion",
    form_btn_loading: "Connexion en cours...",
    form_btn_google: "Se connecter avec Google",
    form_btn_apple: "Se connecter avec Apple",
    form_password_missing_requirements: "Mot de passe invalide ! Manquant :",
    form_req_lowercase_letter: "Au moins une lettre minuscule 0/1",
    form_req_uppercase_letter: "Au moins une lettre majuscule 0/1",
    form_req_number: "Au moins un chiffre 0/1",
    form_req_special_character:
      "Au moins un caractère spécial 0/ 1 (ex: * ^ $ * . [ ] { } ( ) ? \" ! @ # % & / \\ , > < ' : ; | _ ~ ` = + -)",
    form_req_eight_characters: "Au moins 8 caractères",
    form_error: "Nom d'utilisateur ou mot de passe incorrect",
    error_404: "Erreur 404 : Page introuvable",
    forgot_password: "Mot de passe oublié ?",
    form_error_passwords_mismatch: "Les mots de passe ne correspondent pas",
    already_logged_in: "Vous êtes déjà connecté",
    continue: "Continuer sur",
    switch_account: "Se déconnecter et se connecter avec un autre compte",
  },
  en: {
    form_title: "Sign in on",
    form_password_challenge_title: "Set New Password",
    form_login_alternative: "Or with email",
    form_field_email: "E-mail",
    form_field_code: "Confirmation Code",
    form_field_email_placeholder: "Enter your email",
    form_field_code_placeholder: "Enter the confirmation code",
    form_field_reset_password_placeholder: "Reset your password",
    form_field_password: "Password",
    form_field_password_challenge: "New Password",
    form_field_password_placeholder: "Enter your password",
    form_field_password_confirm_challenge: "Confirm new password",
    form_field_password_confirm_placeholder: "Confirm your new password",
    form_btn_submit: "Sign in",
    form_btn_reset: "Reset Password",
    form_btn_code: "Send email",
    form_advice:
      "You will receive your login details by e-mail, redirecting you to the login page.",
    form_btn_loading: "Signing in...",
    form_btn_google: "Sign in with Google",
    form_btn_apple: "Sign in with Apple",
    form_password_missing_requirements: "Invalid password! Missing:",
    form_req_lowercase_letter: "At least one owercase letter 0/1",
    form_req_uppercase_letter: "At least one uppercase letter 0/1",
    form_req_number: "At least one number 0/1",
    form_req_special_character:
      "At least one special character 0/1 (ex: * ^ $ * . [ ] { } ( ) ? \" ! @ # % & / \\ , > < ' : ; | _ ~ ` = + -)",
    form_req_eight_characters: "At least 8 characters",
    form_error: "Incorrect username or password.",
    error_404: "404 Error: Page Not Found",
    forgot_password: "Forgot password?",
    form_error_passwords_mismatch: "Passwords do not match",
    already_logged_in: "You are already logged in.",
    continue: "Continue on",
    switch_account: "No thanks",
  },
  pt: {
    form_title: "Iniciar sessão no",
    form_password_challenge_title: "Definir Nova Senha",
    form_login_alternative: "Ou por e-mail",
    form_field_email: "E-mail",
    form_field_code: "Código de confirmação",
    form_field_email_placeholder: "Introduza o seu e-mail",
    form_field_code_placeholder: "Introduzir o código de confirmação",
    form_field_reset_password_placeholder: "Repor a sua palavra-passe",
    form_field_password: "A sua palavra-passe",
    form_field_password_challenge: "Nova Senha",
    form_field_password_placeholder: "Introduza a sua palavra-passe",
    form_field_password_confirm_challenge: "Confirme a nova senha",
    form_field_password_confirm_placeholder: "Confirme a sua nova senha",
    form_btn_submit: "Ligação",
    form_btn_reset: "Repor a palavra-passe",
    form_btn_code: "Enviar correio eletrónico",
    form_advice:
      "Receberá os seus dados de acesso por correio eletrónico, que o redireccionará para a página de acesso.",
    form_btn_loading: "Ligação em curso...",
    form_btn_google: "Iniciar sessão no Google",
    form_btn_apple: "Iniciar sessão com a Apple",
    form_password_missing_requirements: "Senha inválida! Faltando:",
    form_req_lowercase_letter: "Pelo menos uma letra minúscula 0/1",
    form_req_uppercase_letter: "Pelo menos uma letra maiúscula 0/1",
    form_req_number: "Pelo menos um número 0/1",
    form_req_special_character:
      "Pelo menos um caractere especial 0/1 (ex: * ^ $ * . [ ] { } ( ) ? \" ! @ # % & / \\ , > < ' : ; | _ ~ ` = + -)",
    form_req_eight_characters: "Pelo menos 8 caracteres",
    form_error: "Nome de utilizador ou palavra-passe incorrectos",
    error_404: "404 Erro: Página Não Encontrada",
    forgot_password: "Esqueceu-se da palavra-passe?",
    form_error_passwords_mismatch: "As palavras-passe não coincidem",
    already_logged_in: "Já tem sessão iniciada.",
    continue: "Continuar no",
    switch_account: "Desligar e iniciar sessão com outra conta",
  },
};
