import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Alert,
  Button,
  Card,
  Heading,
  PasswordField,
  TextField,
  View,
  Flex,
  SelectField,
} from "@aws-amplify/ui-react";
import { Link } from "@aws-amplify/ui-react";
import ResetPasswordChallenge from "./ResetPasswordChallengeForm";
import { Amplify, Auth, I18n } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "../aws-exports";
import { getUrlParamsRequired } from "../utils/getUrlParams";

import { strings } from "../strings";
import { checkSession } from "../utils/checkSession";
import Logo from "./Logo";
import { clients } from "../utils/clientData";

I18n.putVocabularies(strings);
Amplify.configure(awsExports);

const languageOptions = [
  { code: "en", label: "English" },
  { code: "fr", label: "Français" },
  { code: "pt", label: "Português" },
];

function LoginForm({ isLoading }) {
  const client = clients.find(
    (client) => client.id === getUrlParamsRequired["client_id"]
  );
  const getUrlParamsString = new URLSearchParams(
    getUrlParamsRequired
  ).toString();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("");

  const navigate = useNavigate();
  const handleLanguageChange = (languageCode) => {
    I18n.setLanguage(languageCode);
    setSelectedLanguage(languageCode);
    window.localStorage.setItem("selectedLanguage", languageCode);
    navigate(`${window.location.pathname}?${getUrlParamsString}`);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const [challengeUser, setChallengeUser] = useState(null);

  const handleSignIn = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      // Try to auth the user with the provided credentials
      const authUser = await Auth.signIn(email.toLowerCase(), password);

      if (authUser.challengeName === "NEW_PASSWORD_REQUIRED") {
        setChallengeUser(authUser);
      }

      const userAuthenticated = await checkSession();
      // If the user is authenticated, we'll try to redirect to the app
      if (userAuthenticated) {
        setError("");
        return window.open(
          `${userAuthenticated.data.redirectLoginUrlWithParams}`,
          "_self"
        );
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const userLocale = navigator.language || navigator.userLanguage;
    const isLocaleValid = languageOptions.some(
      (option) => option.code === userLocale
    );
    const defaultLanguage = isLocaleValid
      ? userLocale
      : languageOptions[0].code;

    const storedLanguage = localStorage.getItem("selectedLanguage");
    const initialLanguage = storedLanguage || defaultLanguage;

    I18n.setLanguage(initialLanguage);
    setSelectedLanguage(initialLanguage);
  }, []);

  if (challengeUser) {
    return (
      <ResetPasswordChallenge
        user={challengeUser}
        onPasswordChanged={() => setChallengeUser(null)}
      />
    );
  }

  return (
    <View>
      <Card variation="elevated" style={{ width: "350px" }}>
        <Logo />
        <Heading textAlign="center" level={4} paddingTop="1rem">
          {I18n.get("form_title")} {client?.name}
        </Heading>
        <form onSubmit={handleSignIn}>
          {error && (
            <Alert variation="error" isDismissible={false} hasIcon={true}>
              {I18n.get("form_error")}
            </Alert>
          )}
          <TextField
            marginTop="1rem"
            label={I18n.get("form_field_email")}
            placeholder={I18n.get("form_field_email_placeholder")}
            value={email}
            type="email"
            onChange={handleEmailChange}
            isRequired={true}
            isDisabled={loading || isLoading}
          />
          <PasswordField
            marginTop="1rem"
            label={I18n.get("form_field_password")}
            placeholder={I18n.get("form_field_password_placeholder")}
            value={password}
            onChange={handlePasswordChange}
            isRequired={true}
            isDisabled={loading || isLoading}
          />
          <Button
            marginTop="1.4rem"
            type="submit"
            size={"small"}
            variation={"primary"}
            loadingText={I18n.get("form_btn_loading")}
            isLoading={loading || isLoading}
            isDisabled={loading || !email || !password || isLoading}
            isFullWidth={true}
          >
            {I18n.get("form_btn_submit")}
          </Button>
        </form>
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          alignContent="flex-start"
          wrap="nowrap"
          paddingTop="1rem"
          gap="1rem"
        >
          <Link
            onClick={() => navigate(`/reset-password?${getUrlParamsString}`)}
          >
            {I18n.get("forgot_password")}
          </Link>
        </Flex>
        <Flex
          direction="row"
          justifyContent="flex-end"
          alignItems="end"
          alignContent="flex-end"
          wrap="nowrap"
          paddingTop="1rem"
          gap="1rem"
        >
          <SelectField
            label={"Language"}
            labelHidden={true}
            size="small"
            style={{ marginRight: 1.2 + "em" }}
            variation="quiet"
            onChange={(event) => handleLanguageChange(event.target.value)}
            value={selectedLanguage}
          >
            {languageOptions.map((option) => (
              <option key={option.code} value={option.code}>
                {option.label}
              </option>
            ))}
          </SelectField>
        </Flex>
      </Card>
    </View>
  );
}

export default LoginForm;
