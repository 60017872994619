// components/LoggedIn.jsx
import React from "react";
import {
  Card,
  Heading,
  Button,
  View,
  Text,
  Icon,
  Flex,
} from "@aws-amplify/ui-react";
import { Auth, I18n } from "aws-amplify";
import { strings } from "../strings";

import Logo from "./Logo";
import { getUrlParamsRequired } from "../utils/getUrlParams";
import { clients } from "../utils/clientData";
I18n.putVocabularies(strings);

const LoggedIn = ({ redirectLoginUrlWithParams }) => {
  const client = clients.find(
    (client) => client.id === getUrlParamsRequired["client_id"]
  );

  const signOut = async () => {
    try {
      await Auth.signOut();
      window.location.reload();
    } catch (error) {
      console.error("error signing out: ", error);
    }
  };

  const onRedirectToLogin = async (callback) => {
    try {
      window.open(callback, "_self");
    } catch (error) {
      console.error("error signing out: ", error);
    }
  };

  return (
    <View>
      <Card variation="elevated" style={{ width: "350px" }}>
        <Logo />
        <Heading textAlign="center" level={4} margin={"1rem"}>
          {I18n.get("already_logged_in")}
        </Heading>

        <Flex
          direction="row"
          justifyContent="space-evenly"
          alignItems="stretch"
          alignContent="flex-center"
          wrap="nowrap"
        >
          <View>
            <Icon
              ariaLabel="users"
              viewBox={{ width: 10, height: 10 }}
              pathData="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
            />
          </View>
          <View>
            <Text margin={"1rem"}>
              {redirectLoginUrlWithParams.session.idToken.payload["email"]}
            </Text>
          </View>
        </Flex>
        <Button
          marginTop="1.4rem"
          type="button"
          variation="primary"
          onClick={() =>
            onRedirectToLogin(
              redirectLoginUrlWithParams.redirectLoginUrlWithParams
            )
          }
          isFullWidth={true}
        >
          {I18n.get("continue")} {client?.name}
        </Button>
        <Button
          marginTop="1.4rem"
          type="button"
          color={"#ec6a2d"}
          backgroundColor={"#fefefe"}
          onClick={signOut}
          isFullWidth={true}
        >
          {I18n.get("switch_account")}
        </Button>
      </Card>
    </View>
  );
};

export default LoggedIn;
