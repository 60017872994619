import React from "react";
import { Alert, Text } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";

const PasswordRequirements = ({ password }) => {
  const isPasswordValid = () => {
    const regex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(){}[\]\-_"'=?\/\\,><:;|_~`+=]).{8,}$/;
    return regex.test(password);
  };

  const getMissingRequirements = () => {
    const missingRequirements = [];

    if (!/(?=.*[a-z])/.test(password)) {
      missingRequirements.push({
        requirement: I18n.get("form_req_lowercase_letter"),
      });
    }
    if (!/(?=.*[A-Z])/.test(password)) {
      missingRequirements.push({
        requirement: I18n.get("form_req_uppercase_letter"),
      });
    }
    if (!/(?=.*\d)/.test(password)) {
      missingRequirements.push({
        requirement: I18n.get("form_req_number"),
      });
    }
    if (!/(?=.*[!@#$%^&*(){}[\]\-_"'=?\/\\,><:;|._~`+=])/.test(password)) {
      missingRequirements.push({
        requirement: I18n.get("form_req_special_character"),
      });
    }
    if (password.length < 8) {
      missingRequirements.push({
        requirement: I18n.get("form_req_eight_characters"),
      });
    }

    return missingRequirements;
  };

  if (password && !isPasswordValid(password)) {
    const missingRequirements = getMissingRequirements(password);

    return (
      <Alert variation={"warning"} hasIcon={false} marginTop="1.4rem">
        <Text>{I18n.get("form_password_missing_requirements")}</Text>
        {missingRequirements.map((req, index) => (
          <Text key={index}>{req.requirement}</Text>
        ))}
      </Alert>
    );
  }

  return null;
};

export default PasswordRequirements;
