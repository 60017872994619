let clients = [];
if (process.env.REACT_APP_ENV === "prod") {
  clients = [
    {
      id: "vhgemlub0mugpok7s3mpnkaur",
      name: "Simulateurs",
      url: [
        "http://localhost:3000/login",
        "https://develop.d3co0w45o7d98d.amplifyapp.com/login",
        "https://tools.viseeon.com/login",
      ],
    },
    {
      id: "2d9hqrejlffigq6ng2uvvfgm50",
      name: "Intranet Viseeon",
      url: [
        "https://fr.ultron.viseeon.io/api/v1/auth/callback",
        "https://pt.ultron.viseeon.io/api/v1/auth/callback",
        "https://my.viseeon.com/api/v1/auth/callback",
        "https://my.viseeon.pt/api/v1/auth/callback",
        "https://fr.thanos.viseeon.io/api/v1/auth/callback",
        "https://v3.viseeon.com/api/v1/auth/callback",
        "https://fr.ultron3.viseeon.io/api/v1/auth/callback",
        "https://v2.viseeon.com/api/v1/auth/callback",
      ],
    },
    {
      id: "27oihomsaeu5kqcrfnlaqlaqc3",
      name: "Welyb",
      url: [
        "https://master.api.welyb.fr/api/oauth/redirect/viseeon",
        "https://api.welyb.fr/api/oauth/redirect/viseeon",
      ],
    },
    {
      id: "1k2r5v1s9sc8ladckgcskckb7n",
      name: "Dynabuy",
      url: [
        "https://www.viseeon-avantages.fr/avantages-prives/connexion",
        "https://www.viseeon-avantages.fr/avantages-entreprises/connexion",
      ],
    },
    {
      id: "36mta68tta3tvddo5esrev37d",
      name: "MCF",
      url: [
        "https://customer.mycompanyfiles.fr/login_viseeon",
        "https://provider.mycompanyfiles.fr/login_viseeon",
      ],
    },
  ];
} else if (process.env.REACT_APP_ENV === "preprod") {
  clients = [
    {
      id: "7v7sbes1nubm4tj1d2576cbjnr",
      name: "Simulateurs",
      url: [
        "http://localhost:3000/login",
        "https://develop.d3co0w45o7d98d.amplifyapp.com/login",
        "https://tools.viseeon.com/login",
      ],
    },
    {
      id: "6e45ui9ubcogik19htbr9ovipn",
      name: "Intranet Viseeon",
      url: [
        "http://localhost:5173/api/v1/auth/callback",
        "https://fr.ultron.viseeon.io/api/v1/auth/callback",
        "https://oidcdebugger.com/debug",
        "https://openidconnect.net/callback",
        "https://ultron.viseeon.io/api/v1/auth/callback",
        "https://pt.galactus.viseeon.io/api/v1/auth/callback",
        "http://pt.galactus.viseeon.io/api/v1/auth/callback",
        "https://fr.galactus.viseeon.io/api/v1/auth/callback",
        "http://fr.galactus.viseeon.io/api/v1/auth/callback",
        "https://galactus.viseeon.io/api/v1/auth/callback",
        "http://galactus.viseeon.io/api/v1/auth/callback",
        "https://pt.galactus3.viseeon.io/api/v1/auth/callback",
        "http://pt.galactus3.viseeon.io/api/v1/auth/callback",
        "https://fr.galactus3.viseeon.io/api/v1/auth/callback",
        "http://fr.galactus3.viseeon.io/api/v1/auth/callback",
        "https://galactus3.viseeon.io/api/v1/auth/callback",
        "http://galactus3.viseeon.io/api/v1/auth/callback",
        "https://pt.ares.viseeon.io/api/v1/auth/callback",
        "http://pt.ares.viseeon.io/api/v1/auth/callback",
        "http://pt.ares.viseeon.io/api/v1/auth/callback",
        "https://fr.ares.viseeon.io/api/v1/auth/callback",
        "http://fr.ares.viseeon.io/api/v1/auth/callback",
        "https://ares.viseeon.io/api/v1/auth/callback",
        "http://ares.viseeon.io/api/v1/auth/callback",
        "http://pt.killmonger.viseeon.io/api/v1/auth/callback",
        "https://pt.killmonger.viseeon.io/api/v1/auth/callback",
        "http://fr.killmonger.viseeon.io/api/v1/auth/callback",
        "https://fr.killmonger.viseeon.io/api/v1/auth/callback",
        "http://killmonger.viseeon.io/api/v1/auth/callback",
        "https://killmonger.viseeon.io/api/v1/auth/callback",
        "https://pt.zemo.viseeon.io/api/v1/auth/callback",
        "http://pt.zemo.viseeon.io/api/v1/auth/callback",
        "https://fr.zemo.viseeon.io/api/v1/auth/callback",
        "http://fr.zemo.viseeon.io/api/v1/auth/callback",
        "https://zemo.viseeon.io/api/v1/auth/callback",
        "http://zemo.viseeon.io/api/v1/auth/callback",
        "https://pt.gorr.viseeon.io/api/v1/auth/callback",
        "http://pt.gorr.viseeon.io/api/v1/auth/callback",
        "https://fr.gorr.viseeon.io/api/v1/auth/callback",
        "http://fr.gorr.viseeon.io/api/v1/auth/callback",
        "https://gorr.viseeon.io/api/v1/auth/callback",
        "http://gorr.viseeon.io/api/v1/auth/callback",
        "https://dev-mahery.d3s58ja57qfs6.amplifyapp.com/api/v1/auth/callback",
        "https://pt.thanos.viseeon.io/api/v1/auth/callback",
        "http://pt.thanos.viseeon.io/api/v1/auth/callback",
        "https://fr.thanos.viseeon.io/api/v1/auth/callback",
        "http://fr.thanos.viseeon.io/api/v1/auth/callback",
        "https://thanos.viseeon.io/api/v1/auth/callback",
        "http://thanos.viseeon.io/api/v1/auth/callback",
      ],
    },
    {
      id: "2q6tqag7loedo77k48dcd43qgg",
      name: "Welyb",
      url: [
        "https://master.api.welyb.fr/api/oauth/redirect/viseeon",
        "https://api.hotfix.welyb.fr/api/oauth/redirect/viseeon",
        "https://api.sprint.welyb.fr/api/oauth/redirect/viseeon",
        "https://api.release.welyb.fr/api/oauth/redirect/viseeon",
        "https://api.dev.welyb.fr/api/oauth/redirect/viseeon",
        "https://api.staging.welyb.fr/api/oauth/redirect/viseeon",
      ],
    },
    {
      id: "tiv8rvps71kmuigbtlb5seu32",
      name: "MCF",
      url: [
        "https://customer-staging.mycompanyfiles.fr/login_viseeon",
        "https://customer-preprod.mycompanyfiles.fr/login_viseeon",
        "https://provider-staging.mycompanyfiles.fr/login_viseeon",
        "https://provider-preprod.mycompanyfiles.fr/login_viseeon",
      ],
    },
    {
      id: "4030b1i1vim46tr6eu14o022g2",
      name: "Dynabuy",
      url: [
        "https://www.viseeon-avantages.fr.staging.dynabuy.fr/avantages-prives/connexion",
        "https://www.viseeon-avantages.fr.staging.dynabuy.fr/avantages-entreprises/connexion",
        "https://www.viseeon-avantages.fr.demo-fklvc3a-shdr3bih7uelg.fr-3.platformsh.site/avantages-prives/connexion",
        "https://www.viseeon-avantages.fr.demo-fklvc3a-shdr3bih7uelg.fr-3.platformsh.site/avantages-entreprises/connexion",
        "https://www.viseeon-avantages.fr.recette-10-azwuyka-shdr3bih7uelg.fr-3.platformsh.site/avantages-prives/connexion",
        "https://www.viseeon-avantages.fr.recette-10-azwuyka-shdr3bih7uelg.fr-3.platformsh.site/avantages-entreprises/connexion",
        "https://www.viseeon-avantages.fr.lan/avantages-prives/connexion",
        "https://www.viseeon-avantages.fr.lan/avantages-entreprises/connexion",
      ],
    },
  ];
}

export { clients };
